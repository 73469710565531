.App {
  /* text-align: center; */
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

.Sidebar {
  min-height: 10vh;
  /* change width of blue sidebar */
  width: 190px;
  background-color: #11324d;
}

.SideBarList {
  padding: 0;
  /* text of the sidebar */
  width: 100%;
  /* background-color: aqua; */
}

.SideBarList #row {
  width: 100%;
  height: 50px;
  /* background-color: blanchedalmond; */
  /* border: 1px solid black; */
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
}

.SideBarList #row:hover {
  cursor: pointer;
  background-color: #293846;
  /* change hover length of sidebar */
  width: 220px;
}

#row #row-icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

#row #row-title {
  flex: 70%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.right-homepage {
  background-color: #ffd9c0;
  min-height: 100vh;
  /* margin:0; */
  /* max-width: 50%; */
  /* margin-left: 50%; */
  /* position:absolute; */
}

.left-homepage {
  background-color: #11324d;
  min-height: 100vh;
}

.Signup {
  display: inline;
  margin-left: 100px;
  margin-right: 50px;
  width: 27%;
  margin-top: 80px;
  color: #ffd9c0;
  background: #393e46;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 40px;
  font-weight: 500;
  cursor: pointer;
}

.Login {
  display: inline;
  margin-left: 50px;
  margin-right: 50px;
  width: 25%;
  margin-top: 80px;
  color: #ffd9c0;
  background: #393e46;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 40px;
  font-weight: 500;
  cursor: pointer;
  /* border-color: #393E46; */
}

/* home page image */
.bookimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-top: 70px;
  height: 400px;
  width: 400px;
}

.Title {
  margin-right: auto;
  margin-left: auto;
  margin-top: 250px;
  width: 340px;
  padding: 50px;
  background: rgb(255, 255, 255);
  font-family: "Lexend Deca", "Otomanopee One";
  color: #000000;
  font-size: 61px;
  font-weight: 500;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
